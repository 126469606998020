/* PACSadvertisement.css */
@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px); /* Adjust the value for the desired bounce height */
  }
}

.bouncing-image {
  animation: bounce 3s ease-in-out infinite; /* Adjust the animation duration as needed */
}

.card-hover-effect :hover {
  background-color: #1b6f83;
  color: 'white';
  transition: background-position 0.3s ease;
}

.animate-charcter {
  background-image: linear-gradient(-225deg, #1a4479 0%, #255795 29%, #255795 67%, #0d004d7d 100%);
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  font-weight: bold;
  background-clip: text;
  text-shadow: 0 2px 4px rgba(13, 0, 77, 0.08), 0 3px 6px rgba(13, 0, 77, 0.08), 0 8px 16px rgba(13, 0, 77, 0.08);
  /* text-fill-color: transparent; */
  -webkit-background-clip: text;
  width: '100%';
  -webkit-text-fill-color: transparent;
  animation: textclip 5s linear infinite;
  display: inline-block;
  /* font-size: 60px; */
  text-align: 'center';
}
@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

/* Footer */
.footer-container {
  max-width: 1170px;
  margin: auto;
}
.footer-row {
  display: flex;
  flex-wrap: wrap;
}
ul {
  list-style: none;
}
.footer {
  background-color: #051d23;
  padding: 70px 0;
  margin-top: 100px;
}
.footer-col {
  width: 25%;
  padding: 0 15px;
}
.footer-col h4 {
  font-size: 18px;
  color: #ffffff;
  text-transform: capitalize;
  margin-bottom: 35px;
  font-weight: 500;
}

.footer-col ul li:not(:last-child) {
  margin-bottom: 10px;
}
.footer-col ul li a {
  font-size: 16px;
  text-transform: capitalize;
  color: #ffffff;
  text-decoration: none;
  font-weight: 300;
  color: #bbbbbb;
  display: block;
  transition: all 0.3s ease;
}
.footer-col ul li a:hover {
  color: #ffffff;
  padding-left: 8px;
}
.footer-col .social-links {
  display: inline-block;
  height: 40px;
  width: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 10px 10px 0;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #ffffff;
  transition: all 0.5s ease;
}
.footer-col .social-links a:hover {
  color: #051d23;
  background-color: #ffffff;
}

.footer-social-icons {
  font-size: 35px;
  color: white;
}

/*responsive*/
@media (max-width: 767px) {
  .footer-col {
    width: 50%;
    margin-bottom: 30px;
  }
}
@media (max-width: 574px) {
  .footer-col {
    width: 100%;
  }
}

.hero-banner {
  /* align-content: center; */
  align-items: center;
  background-color: #121417;
  background: radial-gradient(111% 111% at 74.29% -11%, #000000 0%, #064698 100%),
    linear-gradient(127.43deg, #0f223a 0%, #000000 100%);
  color: #fcfdfd;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  overflow: hidden;
  position: relative;
  text-align: center;
}
.column {
  flex-direction: column;
}
.justify-between {
  justify-content: space-between;
}
.text-center {
  text-align: center;
}

.flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.align-center {
  align-items: center;
}

.justify-end {
  justify-content: end;
}

.pointer {
  cursor: pointer;
}

.training-in-progress-popup {
  z-index: 2147483003;
  border: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  width: 75px;
  height: 75px;
}

.button {
  position: fixed;
  z-index: 2147483003;
  padding: 0 !important;
  margin: 0 !important;
  border: none;
  bottom: 25px;
  right: 40px;
  max-width: 48px;
  width: 48px;
  max-height: 48px;
  height: 48px;
  border-radius: 50%;
  background: #07374d;
  cursor: pointer;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16);
  transition: transform 167ms cubic-bezier(0.33, 0, 0, 1);
  box-sizing: content-box;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.customWelcomeButton {
  background-color: #3771bb;
  padding: 25px 35px;
  color: white;
  border-radius: 6px;
  border-bottom: 4px solid #1a3e6c;
  border-top: 0px;
  transition: all 0.1s ease-in-out;
}
.customWelcomeButton:hover {
  border-bottom-width: 0;
  border-top-width: 4px;
  background-color: #1a3e6c;
}

.customDullWelcomeButton {
  background-color: transparent;
  padding: 25px 35px;
  color: black;
  border-radius: 6px;
  border-bottom: 4px solid #1a3e6c;
  transition: all 0.1s ease-in-out;
}

/* Hide scrollbar for IE, Edge and Firefox */
